import React from 'react';
import { Link } from 'gatsby';

const SiblingPagination = ({ pageContext: { nextPost, previousPost } }) => (
  <div className="uppercase flex justify-between text-xs mb-4 text-green font-semibold">
    {previousPost && previousPost.slug ? (
      <Link title="Go to previous post" to={`/blog/${previousPost.slug}`}>
        ← <span dangerouslySetInnerHTML={{ __html: previousPost.title }} />
      </Link>
    ) : (
      <Link title="Go to blog" to="/blog/page/1">
        ← BLOG
      </Link>
    )}
    {nextPost && nextPost.slug ? (
      <Link title="Go to next page" to={`/blog/${nextPost.slug}`}>
        <span dangerouslySetInnerHTML={{ __html: nextPost.title }} /> →
      </Link>
    ) : (
      <Link title="Go to blog" to="/blog/page/1">
        BLOG →
      </Link>
    )}
  </div>
);

export default SiblingPagination;
