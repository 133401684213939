import React, { useState } from 'react';
import Socials from './socials';

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [displayRequired, setDisplayRequired] = useState(false);

  const validateForm = e => {
    if (!email.length) {
      e.preventDefault();
      setDisplayRequired(true);
      return false;
    }
  };

  return (
    <div className="text-xs">
      <div
        id="mc_embed_signup"
        className="bg-green text-white py-4 px-2 text-center mb-4"
      >
        <p className="text-sm font-semibold text-calico uppercase mb-4 leading-none">
          SUBSCRIBE to the (max?) weekly newsletter
        </p>
        <form
          action="https://space.us4.list-manage.com/subscribe/post?u=ac997d9c29609d1be1e2d0a2d&amp;id=8e8bd9a060"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          onSubmit={validateForm}
          className="m-0 flex justify-center"
        >
          <input
            type="email"
            value={email}
            placeholder="Email Address"
            name="EMAIL"
            id="mce-EMAIL"
            className="text-black px-2 mr-2"
            onChange={e => setEmail(e.target.value)}
          />
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            />
            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          </div>
          <div
            style={{ position: 'absolute', left: '-5000px', width: '0px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_ac997d9c29609d1be1e2d0a2d_8e8bd9a060"
              tabIndex="-1"
              value={email}
              readOnly
            />
          </div>
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            className="uppercase font-bold text-green px-2 bg-calico"
          />
        </form>
        {displayRequired && (
          <div className="text-calico font-semibold mt-2">
            <p className="text-calico inline">^ your email please</p>
          </div>
        )}
        <div className="mt-4">
          <Socials colour="calico" />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionForm;
