import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import cx from 'classnames';
import {
  InstagramIcon,
  TwitterIcon,
  MailIcon,
  LinkedInIcon
} from '../assets/icons';

const Socials = ({ colour }) => (
  <div
    className={cx('flex justify-center', {
      'text-blue': colour === 'blue',
      'text-calico': colour === 'calico'
    })}
  >
    <a href="mailto:hello@cloppy.space">
      <MailIcon />
    </a>
    <OutboundLink href="https://instagram.com/cloppy_">
      <InstagramIcon />
    </OutboundLink>
    <OutboundLink href="https://twitter.com/cloppy_">
      <TwitterIcon />
    </OutboundLink>
    <OutboundLink href="https://www.linkedin.com/in/chloephua/">
      <LinkedInIcon />
    </OutboundLink>
  </div>
);

export default Socials;
