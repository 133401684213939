import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

const debounce = (func, wait, immediate) => {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const Header = ({ siteTitle, onReset }) => {
  const [displayHomeLink, setDisplayHomeLink] = useState(true);

  const handleScroll = debounce(() => {
    const shouldDisplay = window.pageYOffset < 30;
    setDisplayHomeLink(shouldDisplay);
  }, 16);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="fixed left-0 top-0">
      <Link
        to="/"
        style={{
          color: `black`,
          textDecoration: `none`
        }}
        className={cx('flex text-lg p-2 cursor-pointer', {
          hidden: !displayHomeLink
        })}
      >
        <div
          className="logo"
          onClick={e => {
            if (!onReset) return;
            e.preventDefault();
            onReset();
          }}
        >
          <svg viewBox="0 0 48 48" width="48" height="48">
            <path id="curve" d="M0,48 Q24,0 48,16" fill="none" y="0" />
            <text x="8" width="48">
              <textPath xlinkHref="#curve" href="#curve">
                &nbsp;&nbsp;CLOPPY
              </textPath>
            </text>
          </svg>
          <span role="img" className="hole" aria-label="hole-emoji">
            🕳
          </span>
        </div>
      </Link>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
