import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SiblingPagination from '../components/siblingPagination';
import SubscriptionForm from '../components/subscriptionForm';
import Footer from '../components/footer';
// import PostIcons from '../components/post-icons';
// import Img from 'gatsby-image';
// import Layout from '../layouts';

const getTextContent = html => {
  if (typeof document === 'undefined') return;
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const PostTemplate = ({ data, pageContext }) => {
  const post = data.wordpressPost;

  return (
    <Layout>
      <SEO
        title={getTextContent(post.title)}
        description={getTextContent(post.excerpt)}
        article
      />
      <div className="w-full flex pt-16 md:pt-20 lg:pt-24 px-8 sm:px-16 flex-col flex-grow content postContent">
        <div className="flex-grow mb-8">
          <div className="text-center text-xs mb-2 uppercase text-green">
            {post.date}
          </div>
          <div
            className="text-center text-2xl mb-6 uppercase text-green title font-semibold italic leading-normal"
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <hr />

          <div
            className="text-xs blogContent leading-loose"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
        <SiblingPagination pageContext={pageContext} />
        <SubscriptionForm />
        <Footer />
      </div>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostTemplate($id: String) {
    wordpressPost(id: { eq: $id }) {
      title
      excerpt
      content
      date(formatString: "MMM Do, YYYY")
      link
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
